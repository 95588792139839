import { cdnApi } from '@mk/services'
import React, { useEffect, useState } from 'react'
import { loadImg } from '../shared/utils'

export default function AtmosphereFG({
  atmosphere
}) {
  const [headSize, setHeadSize] = useState({ width: 0, height: 0 })
  const { fill = false } = atmosphere

  useEffect(() => {
    const getHeadSize = async () => {
      if (!atmosphere.url) return
      /** 通过 new Image 方法获取head图片的宽高 */
      const headSize = await loadImg(cdnApi(atmosphere.url))
      setHeadSize(headSize)
    }

    const init = async () => {
      /** promise all to get img size */
      await Promise.all([getHeadSize()])
    }
    init()
  }, [atmosphere.url])

  return (
    <div className="atmosphere_bg" style={{
      backgroundImage: `url("${atmosphere.url}")`,
      aspectRatio: fill ? undefined : `${headSize.width} / ${headSize.height}`,
      pointerEvents: 'none',
      zIndex: 999999,
      backgroundSize: 'contain',
      backgroundRepeat: fill ? 'repeat' : 'no-repeat'
    }}></div>
  )
}
