import { cdnApi, getIsFlatPages, getWorksDetailStatic } from "@mk/services"
import React, { useState, useEffect, useMemo, useRef } from "react"
import { PlatformCompProps } from "@mk/widgets-bridge-sdk/types"
import { WorksBackground } from "@mk/works-store/types"
import { isAndroid, isWechat, queryToObj, random } from "@mk/utils"
import ClicpModeBg, { ClicpModeBgProps } from "./ClicpModeBg"

import './index.scss'
import AtmosphereFG from "./AtmosphereFG"
import ClicpModeBgV2 from "./ClicpModeBgV2"

export interface MkBgProps {
  clipBgLayout?: ClicpModeBgProps['clipBgLayout']
  clipBgChildren?: ClicpModeBgProps['clipBgChildren']
  bgData: WorksBackground
  needScale?: boolean
  width: number
  height: number
  scale: number
}

const Background: React.FC<PlatformCompProps<MkBgProps>> = (props) => {
  const { controledValues, lifecycle, containerInfo, canvaInfo } = props
  const {
    bgData,
    needScale = false,
    clipBgChildren,
    clipBgLayout,
    scale = 1,
    height,
    width
  } = controledValues
  // console.log('controledValues', controledValues)
  const {
    atmosphere,
    originwidth = 720,
    clipBgSetting,
    clipBgSettingV2,
    bgcolor = '',
    bgpictype,
    opacity,
    bgpicleft = 0,
    bgpictop = 0,
    bgpicwidth = 1,
    flipHorizontal,
    flipVertical,
  } = bgData || {}
  const videoId = useRef(random())
  const worksType = getWorksDetailStatic().type
  const isPosterMode = worksType === 'poster'
  let canvasScale = +queryToObj().canvas_scale
  if (Number.isNaN(canvasScale)) {
    canvasScale = 1
  }
  /** h5 放大3倍 */
  const imageScaleRate = isPosterMode ? 2 : 4
  const resizeWidth = Math.min(width * canvasScale * imageScaleRate, originwidth)
  let _resize = true
  if (resizeWidth >= originwidth) {
    _resize = false
  }

  const isVideo = /mp4/ig.test(bgData?.bgpic || '')

  const bgpic = !isVideo && bgData.bgpic ? cdnApi(bgData.bgpic, _resize ? { resizeWidth } : undefined) : bgData.bgpic
  const { materialId } = bgData

  const bgImgRef = React.useRef<HTMLDivElement>(null)

  const backgroundImgStyle = (): React.CSSProperties => {
    if (bgData == null) {
      return {}
    }

    // 海报模式
    const canvaWidth = width
    const canvaHeight = height
    const bgImg = `url("${bgpic || ''}")`
    const top = bgpictop
    const left = bgpicleft
    const imageWidth = bgpicwidth
    const backgroundObj: React.CSSProperties = {
      backgroundImage: bgImg,
      backgroundSize: `${imageWidth * scale}px`,
      backgroundPosition: ` ${left * scale}px ${top * scale}px`,
      opacity: `${opacity}`,
      transform: `scale(${flipHorizontal ? -1 : 1}, ${flipVertical ? -1 : 1})`,
    }

    if (needScale) {
      Object.assign(backgroundObj, {
        top: '50%',
        left: '50%',
        marginLeft: `${-canvaWidth / 2}px`,
        marginTop: `${-canvaHeight / 2}px`,
        width: `${canvaWidth}px`,
        height: `${canvaHeight}px`,
        transform: `scale(${flipHorizontal ? -scale : scale}, ${flipVertical ? -scale : scale})`,
      })
    }

    return backgroundObj
  }

  const backgroundColorStyle = (): React.CSSProperties => {
    if (bgData == null) {
      return {}
    }

    const backgroundObj: React.CSSProperties = {
      background: bgcolor,
      opacity: `${opacity}`,
    }

    return backgroundObj
  }

  useEffect(() => {
    if (!bgpic && !clipBgSetting) {
      console.log('没有背景图')
      return lifecycle.didLoaded?.()
    } else if (bgpic) {
      if (isVideo) {
        const video = document.querySelector<HTMLVideoElement>(`#bg_video_${videoId.current}`)
        video?.addEventListener('loadeddata', () => {
          lifecycle.didLoaded?.()
        })
        video?.addEventListener('error', () => {
          lifecycle.didLoaded?.()
        })
      } else {
        const image = new Image()
        image.onload = () => {
          lifecycle.didLoaded?.()
        }
        image.onerror = () => {
          lifecycle.didLoaded?.()
        }
        image.src = bgpic
      }
    } else {
      console.error('背景图加载失败')
    }
  }, [])

  useEffect(() => {
    const playVideo = () => {
      const $video1 = document.querySelector<HTMLVideoElement>(`#bg_video_${videoId.current}`)
      $video1?.play()
    }
    // 兼容微信自动播放
    function doPlay() {
      (window as any).WeixinJSBridge?.invoke('getNetworkType', {}, (e) => {
        playVideo()
      })
    }

    function handleTouchStart() {
      playVideo()
    }

    if ((window as any).WeixinJSBridge) {
      doPlay()
    } else {
      document.addEventListener("WeixinJSBridgeReady", doPlay)
    }

    if (isWechat() && isAndroid()) {
      document.addEventListener('touchstart', handleTouchStart)
    }

    return () => {
      document.removeEventListener("WeixinJSBridgeReady", doPlay)
      document.removeEventListener('touchstart', handleTouchStart)
    }
  }, [])


  const renderBg = () => {
    if (isVideo) {
      return (
        <>
          <div className="background-editing-mask bg_fill_video_container" style={{ pointerEvents: 'none' }}>
            <video
              key={bgpic}
              autoPlay loop muted
              playsInline
              webkit-playsInline="true"
              x5-video-player-type="h5"
              x-webkit-airplay="true"
              x5-video-orientation="portraint"
              id={`bg_video_${videoId.current}`}
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}>
              <source src={bgpic} type="video/mp4" />
            </video>
          </div>
          <div className="bg_fill_color_container" style={backgroundColorStyle()}></div>
        </>
      )
    }
    if (bgpictype === 'picture') {
      return (
        <div className="background-editing-mask bg_fill_img_container" ref={bgImgRef} style={backgroundImgStyle()}></div>
      )
    } else if (bgpictype === 'color') {
      return (
        <div className="bg_fill_color_container" style={backgroundColorStyle()}></div>
      )
    } else if (bgpictype === 'mix') {
      return (
        <>
          <div className="background-editing-mask bg_fill_img_container" ref={bgImgRef} style={backgroundImgStyle()}></div>
          <div className="bg_fill_color_container" style={backgroundColorStyle()}></div>
        </>
      )
    }
  }

  return (
    <>
      <div className="viewer_background_container" data-picturemask={`${!!materialId}`} data-material-id={materialId}>
        {renderBg()}
      </div>
      {
        clipBgSetting && (
          <ClicpModeBg clipBgSetting={clipBgSetting} clipBgLayout={clipBgLayout} clipBgChildren={clipBgChildren}
            didLoaded={() => {
              lifecycle.didLoaded?.()
            }}
          />
        )
      }
      {
        clipBgSettingV2 && (
          <ClicpModeBgV2
            clipBgSettingV2={clipBgSettingV2}
            clipBgLayout={clipBgLayout}
            canvaInfo={canvaInfo}
            containerInfo={containerInfo}
            didLoaded={() => {
              lifecycle.didLoaded?.()
            }}
          />
        )
      }
      {
        atmosphere?.url && <AtmosphereFG atmosphere={atmosphere} />
      }
    </>
  )
}

export default Background
