import { cdnApi } from '@mk/services'
import { WorksBackground } from '@mk/works-store/types'
import React, { useEffect, useRef, useState } from 'react'
import { loadImg } from '../shared/utils'

export interface ClicpModeBgProps {
  clipBgSetting: WorksBackground['clipBgSetting']
  clipBgLayout?: 'row' | 'column'
  clipBgChildren?: any
  didLoaded?: () => void
}

export default function ClicpModeBg(props: ClicpModeBgProps) {
  const { clipBgSetting, clipBgLayout = 'column', clipBgChildren, didLoaded } = props
  const loadCount = useRef(0)

  const [headSize, setHeadSize] = useState({ width: 0, height: 0 })
  const [bodySize, setBodySize] = useState({ width: 0, height: 0 })
  const [footSize, setFootSize] = useState({ width: 0, height: 0 })

  const headUrl = clipBgSetting?.head?.ossPath
  const bodyUrl = clipBgSetting?.body?.ossPath
  const footUrl = clipBgSetting?.foot?.ossPath

  useEffect(() => {
    const loadImageCounter = () => {
      loadCount.current++
      if (loadCount.current === 2) {
        didLoaded?.()
      }
    }

    const getHeadSize = async () => {
      if (headUrl) {
        /** 通过 new Image 方法获取head图片的宽高 */
        const headSize = await loadImg(cdnApi(headUrl))
        setHeadSize(headSize)
      }
      loadImageCounter()
    }
    const getBodySize = async () => {
      if (bodyUrl) {
        /** 通过 new Image 方法获取head图片的宽高 */
        const headSize = await loadImg(cdnApi(bodyUrl))
        setBodySize(headSize)
      }
      loadImageCounter()
    }
    const getFootSize = async () => {
      if (footUrl) {
        /** 通过 new Image 方法获取head图片的宽高 */
        const headSize = await loadImg(cdnApi(footUrl))
        setFootSize(headSize)
      }
      loadImageCounter()
    }

    const init = async () => {
      /** promise all to get img size */
      await Promise.all([getHeadSize(), getBodySize(), getFootSize()])
    }
    init()
  }, [clipBgSetting])

  if (!clipBgSetting) return null
  const colLayout = clipBgLayout === 'column'

  return (
    <div
      className='clip_bg_container'
      style={{
        display: 'flex',
        flexDirection: clipBgLayout === 'column' ? 'column' : 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
      }}>
      {headUrl && <div
        className='head_bg'
        style={colLayout ? {
          backgroundSize: 'cover',
          aspectRatio: `${headSize.width} / ${headSize.height}`,
          width: '100%',
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url("${cdnApi(headUrl)}")`,
        } : {
          width: headSize.width,
          height: headSize.height,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url("${cdnApi(headUrl)}")`,
        }}
      >
      </div>}
      {bodyUrl ? <div
        className='body_bg'
        style={colLayout ? {
          aspectRatio: `${bodySize.width} / ${bodySize.height}`,
          backgroundSize: 'contain',
          backgroundImage: `url("${cdnApi(bodyUrl)}")`,
          backgroundRepeat: 'repeat-y',
          width: '100%',
          flex: '1 1 auto',
        } : {
          height: bodySize.height,
          backgroundPosition: 'center',
          backgroundImage: `url("${cdnApi(bodyUrl)}")`,
          backgroundRepeat: 'repeat-x',
        }}
      >
        {clipBgChildren}
      </div> : clipBgChildren}
      {footUrl && <div
        className='foot_bg'
        style={colLayout ? {
          aspectRatio: `${footSize.width} / ${footSize.height}`,
          width: '100%',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url("${cdnApi(footUrl)}")`,
        } : {
          width: footSize.width,
          height: footSize.height,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url("${cdnApi(footUrl)}")`,
        }}
      >
      </div>}
    </div>
  )
}
