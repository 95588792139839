import { cdnApi } from '@mk/services'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { loadImg } from '../shared/utils'
import { MkBgProps } from '../shared/types'

export interface ClicpModeBgProps {
  clipBgSettingV2: MkBgProps['clipBgSettingV2']
  clipBgLayout?: 'row' | 'column'
  didLoaded?: () => void
  containerInfo
  canvaInfo
}

export default function ClicpModeBgV2(props: ClicpModeBgProps) {
  const { clipBgSettingV2, clipBgLayout = 'row', containerInfo, canvaInfo, didLoaded } = props

  const [bgGroupSize, setBgGroupSize] = useState<Array<{ width: number; height: number }>>([])
  const currentDOM = useRef<HTMLDivElement>(null)
  const [scale, setScale] = useState<number>(1)
  const colLayout = clipBgLayout === 'column'

  useEffect(() => {
    const getSizeProm = clipBgSettingV2.map(async (item, index) => {
      if (item) {
        const headSize = await loadImg(cdnApi(item.ossPath))
        return { width: headSize.width, height: headSize.height }
      } else {
        return { width: 0, height: 0 }
      }
    })

    const init = async () => {
      /** promise all to get img size */
      const imgSizes = await Promise.all(getSizeProm)
      setBgGroupSize(imgSizes)
      didLoaded?.()
      // console.log('scale', isValidedScale, scale)
    }
    init()
  }, [clipBgSettingV2])

  useLayoutEffect(() => {
    if (bgGroupSize.length === 0) return
    const scale = containerInfo.height / bgGroupSize[0].height
    const isValidedScale = !Number.isNaN(scale)
    if (isValidedScale) setScale(scale)
    return () => {
    }
  }, [containerInfo.width, containerInfo.height, bgGroupSize.length])


  if (!clipBgSettingV2 || clipBgSettingV2.length < 3) return null

  return (
    <div
      className='clip_bg_container'
      ref={currentDOM}
      style={{
        display: 'flex',
        flexDirection: colLayout ? 'column' : 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
      }}>
      {
        bgGroupSize.map((imgSize, index) => {
          // const imgSize = bgGroupSize[index]
          const item = clipBgSettingV2[index]
          const url = item.ossPath || ''
          const stretchItem = [1, 3, 4, 5, 7]
          return (
            <div
              key={index}
              className={`clip_bg_item idx_${index}`}
              style={Object.assign({}, {
                aspectRatio: `${imgSize.width} / ${imgSize.height}`,
                flex: stretchItem.includes(index) ? 1 : 'unset',
                backgroundImage: `url("${cdnApi(url)}")`,
                backgroundRepeat: 'repeat',
              }, colLayout ? {
                backgroundSize: !stretchItem.includes(index) ? 'cover' : 'contain',
                width: '100%',
              } : {
                width: imgSize.width * scale,
                height: imgSize.height * scale,
                backgroundPosition: 'center',
                // backgroundSize: 'contain',
                backgroundSize: stretchItem.includes(index) ? 'contain' : 'cover',
              })}
            >
            </div>
          )
        })
      }
    </div>
  )
}
