export const loadImg = (url) => {
  return new Promise<{ width; height }>((resolve, reject) => {
    const img = new Image()
    img.src = url
    img.onload = () => {
      resolve({
        width: img.width,
        height: img.height,
      })
    }
  })
}
